import React, { FC, createContext, useReducer } from 'react';

import { laboratoriesApi } from 'api';
import { loadAllPages, makeReducer } from 'utils/functions';
import { getErrorMessage } from 'utils/issues';
import Notification from 'utils/notification';
import { httpClient } from 'services/index';
import { RcFile } from 'antd/lib/upload/interface';

export type Laboratory = {
  id: string;
  attentionName: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  district1: string;
  email: string;
  textConfig: Record<string, any>[] | null;
  phoneNumber: string;
  postalCode: string;
  uspsMerchantAccountId: string;
  uspsMid: string;
  createdAt: string;
  updatedAt: string;
  reqFormKey: string;
  reqFormUrl: string;
  adapterKey: string;
  uspsMerchantAccountCode: string;
  uspsShipMid: string;
};

const sortByName = (a: Laboratory, b: Laboratory): number => a.name.localeCompare(b.name);

const showErrorNotification = (message: string, description: string): void => {
  const allErrors = description.split('\n');

  // single error: show for 4 seconds and hide (default behavior)
  if (allErrors.length === 1) {
    Notification({
      type: 'error',
      message,
      description: allErrors[0],
    });

    return;
  }

  // multiple errors: do not hide automatically, display as list
  Notification({
    type: 'error',
    message,
    description: (
      <ul style={{ margin: 0, padding: 0 }}>
        {allErrors.map((err) => (
          <li>{err}</li>
        ))}
      </ul>
    ),
    duration: 0,
  });
};

const formatLaboratory = (lab: any): Laboratory => {
  const {
    id,
    attentionName,
    name,
    address1,
    address2,
    city,
    district1,
    email,
    textConfig,
    phoneNumber,
    postalCode,
    uspsMerchantAccountId,
    uspsMid,
    createdAt,
    updatedAt,
    reqFormKey,
    reqFormUrl,
    adapterKey,
    uspsMerchantAccountCode,
    uspsShipMid,
  } = lab;

  return {
    id,
    attentionName: attentionName || '',
    name: name || '',
    address1: address1 || '',
    address2: address2 || '',
    city: city || '',
    district1: district1 || '',
    email: email || '',
    textConfig,
    phoneNumber: phoneNumber || '',
    postalCode: postalCode || '',
    uspsMerchantAccountId: uspsMerchantAccountId || '',
    uspsMid: uspsMid || '',
    createdAt,
    updatedAt: updatedAt || '',
    reqFormKey: reqFormKey || '',
    reqFormUrl: reqFormUrl || '',
    adapterKey: adapterKey || '',
    uspsMerchantAccountCode: uspsMerchantAccountCode || '',
    uspsShipMid: uspsShipMid || '',
  };
};

type State = {
  laboratories: Laboratory[];
  laboratory: Laboratory | null;
  loading: boolean;
  error: string | null;
};

type SingleResultCallback = (laboratory: Laboratory | null) => Promise<void> | void;

type GetLaboratoryFunction = (
  id: string,
  options?: { withReqFormUrl?: boolean; skipStateUpdate?: boolean },
  callback?: SingleResultCallback
) => Promise<void>;

type Context = State & {
  getLaboratories: (callback?: any) => Promise<void>;
  getLaboratory: GetLaboratoryFunction;
  createLaboratory: (data: any, callback?: any) => Promise<any>;
  updateLaboratory: (id: string, data: any, reqFormFile?: RcFile, callback?: any) => Promise<void>;
  deleteLaboratory: (id: string, callback?: any) => Promise<void>;
  resetLaboratories: () => void;
};

const getInitialState = (): State => ({
  laboratories: [],
  laboratory: null,
  loading: false,
  error: null,
});

const LaboratoriesContext = createContext<Context>({
  ...getInitialState(),
  getLaboratories: async () => {},
  getLaboratory: async () => {},
  createLaboratory: async () => {},
  updateLaboratory: async () => {},
  deleteLaboratory: async () => {},
  resetLaboratories: () => {},
});

const LaboratoriesProvider: FC<any> = (props) => {
  const [state, setState] = useReducer(makeReducer<State>(), getInitialState());

  const getLaboratories = async (callback?: any): Promise<void> => {
    try {
      setState({
        loading: true,
        error: null,
      });

      const laboratories = await loadAllPages<any, any>(
        laboratoriesApi.getLaboratories,
        {},
        {
          pageLength: 50,
          sortBy: 'createdAt',
          isDescending: false,
        }
      );

      setState({
        loading: false,
        laboratories: laboratories.map(formatLaboratory).sort(sortByName),
      });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      showErrorNotification('Cannot get laboratories', errorMessage);
    }
  };

  const getLaboratory: GetLaboratoryFunction = async (id, options?, callback?) => {
    const skipStateUpdate = options?.skipStateUpdate === true;

    if (!skipStateUpdate) {
      setState({ loading: true, error: null });
    }

    try {
      const laboratory = await laboratoriesApi.getLaboratory(
        id,
        options?.withReqFormUrl ? { 'tasso-hydration': 'reqFormUrl' } : {}
      );

      if (!skipStateUpdate) {
        setState({
          laboratory: formatLaboratory(laboratory),
        });
      }

      if (callback) {
        await callback(laboratory);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      if (!skipStateUpdate) {
        setState({
          error: errorMessage,
        });
      }

      showErrorNotification('Cannot get laboratory details', errorMessage);

      if (callback) {
        await callback(null);
      }
    } finally {
      if (!skipStateUpdate) {
        setState({
          loading: false,
        });
      }
    }
  };

  const createLaboratory = async (data: any, callback?: any): Promise<void> => {
    try {
      setState({ loading: true, error: null });

      const newLaboratory = await laboratoriesApi.createLaboratory(data);

      setState({
        loading: false,
        laboratories: [...state.laboratories, formatLaboratory(newLaboratory)].sort(sortByName),
      });

      Notification({ type: 'success', message: 'Laboratory created' });

      if (callback) {
        await callback(newLaboratory);
      }
    } catch (e: any) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      showErrorNotification('Laboratory not created', errorMessage);
    }
  };

  const updateLaboratory = async (id: string, data: any, reqFormFile?: RcFile, callback?: any): Promise<void> => {
    try {
      setState({
        loading: true,
        error: null,
      });

      let updatedLaboratory: any;

      if (reqFormFile) {
        updatedLaboratory = await laboratoriesApi.updateLaboratory(id, data);

        const reqFormUploadInfo = JSON.parse(updatedLaboratory.reqFormUploadInfo);

        const formData = new FormData();
        formData.append('Content-Type', reqFormFile!.type);
        Object.entries(reqFormUploadInfo.fields).forEach(([k, v]) => {
          formData.append(k, v as string);
        });
        formData.append('file', reqFormFile!);

        await httpClient.post(reqFormUploadInfo.url, formData, { ignoreAuthorization: true } as any);

        // Wait a little bit for API to process the file. Once it's done,
        // the `fileKey` property will populated.
        let attempts = 0;
        do {
          // eslint-disable-next-line no-await-in-loop
          updatedLaboratory = await laboratoriesApi.getLaboratory(updatedLaboratory.id);

          if (updatedLaboratory.reqFormKey) {
            break;
          }

          // eslint-disable-next-line no-await-in-loop
          await new Promise((r) => {
            setTimeout(r, 500);
          });

          attempts += 1;
        } while (attempts < 10);
      } else {
        updatedLaboratory = await laboratoriesApi.updateLaboratory(id, data);
      }

      setState({
        loading: false,
        laboratories: state.laboratories
          .map((lab) => (lab.id === id ? formatLaboratory(updatedLaboratory) : lab))
          .sort(sortByName),
        laboratory:
          state.laboratory && state.laboratory.id === id ? formatLaboratory(updatedLaboratory) : state.laboratory,
      });

      Notification({ type: 'success', message: 'Laboratory updated' });

      if (callback) {
        await callback();
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      showErrorNotification('Laboratory not updated', errorMessage);
    }
  };

  const deleteLaboratory = async (id: string, callback?: any): Promise<void> => {
    try {
      setState({
        loading: true,
        error: null,
      });

      const deletedLaboratory = await laboratoriesApi.deleteLaboratory(id);

      const labIndex = state.laboratories.findIndex((lab) => lab.id === id);

      setState({
        loading: false,
        laboratories:
          labIndex > -1 ? state.laboratories : state.laboratories.filter((_lab, index) => index !== labIndex),
        laboratory: state.laboratory && state.laboratory.id === id ? null : state.laboratory,
      });

      Notification({ type: 'success', message: 'Laboratory deleted' });

      if (callback) {
        await callback(deletedLaboratory);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);

      setState({
        loading: false,
        error: errorMessage,
      });

      showErrorNotification('Laboratory not deleted', errorMessage);
    }
  };

  const resetLaboratories = (): void => {
    setState(getInitialState());
  };

  return (
    <LaboratoriesContext.Provider
      value={{
        laboratories: state.laboratories,
        laboratory: state.laboratory,
        loading: state.loading,
        error: state.error,
        getLaboratories,
        getLaboratory,
        createLaboratory,
        updateLaboratory,
        deleteLaboratory,
        resetLaboratories,
      }}
      {...props}
    />
  );
};

const useLaboratories = (): Context => React.useContext(LaboratoriesContext);

export { LaboratoriesProvider, useLaboratories };
