import React from 'react';

import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppProviders from './AppProviders';
import Router from './router';

import 'less/styles.less';

const ffUrl = process.env.FEATURE_FLAG_PROXY_URL || 'https://ff.dev.services.tassocare.com/proxy';
const ffKey = process.env.FEATURE_FLAG_PROXY_CLIENT_KEY || 'DfEcioVUv8emX2-Uehqq';
const envName = process.env.CI_ENVIRONMENT_NAME || 'Development';
const unleashConfig = {
  url: ffUrl,
  clientKey: ffKey,
  appName: envName, // Assigning the environment name to the appName is intentional. See bug here: https://gitlab.com/gitlab-org/gitlab/-/issues/354087
  refreshInterval: 15,
};

render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProviders unleashConfig={unleashConfig}>
        <Router />
      </AppProviders>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
