import React, { FC, useEffect, useMemo, useState } from 'react';

import { Form, Input, Select, DatePicker, Modal, Row, Col, Checkbox } from 'antd';
import { ExportOutlined } from 'components/icons';

import { ASSIGNED_SEXES, GENDERS, NO_ORDER_PLEASE, RACES } from 'constants/index';
import { IPatient } from 'context/patients';
import { CustomAttributeDefinitionProject, IProject } from 'context/projects';
import validator from 'validator';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { OrderTemplate } from '@tassoinc/core-api-client';
import { generateCustomAttributeElements, getSelectedCustomAttributes } from 'utils/customAttributeDefinitions';
import { getDefaultOrderTemplate } from 'utils/orderTemplates';

const isValidPhoneNumber = (phoneNumber: any): boolean =>
  // Currently, we only support patient phone numbers which are in the United States,
  // so we're checking that it's a valid US phone number and also begins with country code '1'
  typeof phoneNumber === 'string' &&
  phoneNumber.length > 0 &&
  phoneNumber[0] === '1' &&
  validator.isMobilePhone(phoneNumber, 'en-US');

/**
 * Describes properties for each of the patient fields displayed on Add/Edit Patient modal.
 */
type PatientFieldInfo = {
  name: string;
  label: string;
  isVisible: boolean;
  isRequired: boolean;
};
type PatientProperty =
  | 'firstName'
  | 'lastName'
  | 'dob'
  | 'assignedSex'
  | 'gender'
  | 'race'
  | 'subjectId'
  | 'address1'
  | 'address2'
  | 'city'
  | 'district1'
  | 'postalCode'
  | 'country'
  | 'phoneNumber'
  | 'email'
  | 'orderingPhysicianNpiId'
  | 'orderingPhysicianFirstName'
  | 'orderingPhysicianLastName'
  | 'deviceBarcode';
type PatientFormValues = Record<PatientProperty, PatientFieldInfo>;

interface IPatientModal {
  form: any;
  patient: IPatient | null;
  project: IProject;
  orderTemplates: OrderTemplate[];
  customAttributeDefinitions: Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>;
  visible: boolean;
  onOk: (patient: IPatient) => void;
  onCancel: () => void;
  loading: boolean;
}

const PatientModal: FC<IPatientModal> = ({
  form,
  patient,
  project,
  orderTemplates,
  customAttributeDefinitions,
  visible,
  onOk,
  onCancel,
  loading,
}) => {
  const defaultOrderTemplateId = useMemo(() => {
    const defaultTemplate = getDefaultOrderTemplate(orderTemplates, project.orderTemplatesViewOrder);

    return defaultTemplate?.id;
  }, [orderTemplates, project]);

  const [selectedOrderTemplateId, setSelectedOrderTemplateId] = useState<string | null>(defaultOrderTemplateId || null);

  const selectedCustomAttributes = getSelectedCustomAttributes(
    selectedOrderTemplateId,
    project,
    customAttributeDefinitions
  );
  const showCustomAttributeFields =
    project.distributionModel !== 'atCustomerSite' && selectedCustomAttributes.length > 0;
  const numCustomAttribs = selectedCustomAttributes.length ?? 0;

  // TODO -> Remove logic relying on "project.purpose" after FND-167.
  const patientFormValues: PatientFormValues = {
    // Mandatory input fields -> Always visible and required.
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      isVisible: true,
      isRequired: true,
    },
    address1: {
      name: 'address1',
      label: 'Shipping address #1',
      isVisible: true,
      isRequired: true,
    },
    city: {
      name: 'city',
      label: 'Shipping city',
      isVisible: true,
      isRequired: true,
    },
    district1: {
      name: 'district1',
      label: 'Shipping state',
      isVisible: true,
      isRequired: true,
    },
    postalCode: {
      name: 'postalCode',
      label: 'Shipping zip',
      isVisible: true,
      isRequired: true,
    },
    country: {
      name: 'country',
      label: 'Country',
      isVisible: true,
      isRequired: true,
    },
    // Optional input fields -> Visible/Required depending on project configuration.
    firstName: {
      name: 'firstName',
      label: 'First Name',
      isVisible: project.patientPropertyConfig?.firstName !== 'hidden',
      isRequired: project.patientPropertyConfig?.firstName === 'required',
    },
    dob: {
      name: 'dob',
      label: 'Date of Birth',
      isVisible: project.patientPropertyConfig?.dob !== 'hidden' || project.purpose === 'diagnosticTesting',
      isRequired: project.patientPropertyConfig?.dob === 'required' || project.purpose === 'diagnosticTesting',
    },
    assignedSex: {
      name: 'assignedSex',
      label: 'Assigned Sex at Birth',
      isVisible: project.patientPropertyConfig?.assignedSex !== 'hidden' || project.purpose === 'diagnosticTesting',
      isRequired: project.patientPropertyConfig?.assignedSex === 'required' || project.purpose === 'diagnosticTesting',
    },
    gender: {
      name: 'gender',
      label: 'Gender',
      isVisible: project.patientPropertyConfig?.gender !== 'hidden',
      isRequired: project.patientPropertyConfig?.gender === 'required',
    },
    race: {
      name: 'race',
      label: 'Race',
      isVisible: project.patientPropertyConfig?.race !== 'hidden',
      isRequired: project.patientPropertyConfig?.race === 'required',
    },
    subjectId: {
      name: 'subjectId',
      label: 'Subject ID',
      isVisible: project.patientPropertyConfig?.subjectId !== 'hidden',
      isRequired: project.patientPropertyConfig?.subjectId === 'required',
    },
    address2: {
      name: 'address2',
      label: 'Shipping address #2',
      isVisible: project.patientPropertyConfig?.address2 !== 'hidden',
      isRequired: project.patientPropertyConfig?.address2 === 'required',
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone (15417543010)',
      isVisible:
        project.package1Type === 'priorityExpress' ||
        project.package2Type === 'priorityExpress' ||
        project.patientPropertyConfig?.phoneNumber !== 'hidden',
      isRequired:
        project.package1Type === 'priorityExpress' ||
        project.package2Type === 'priorityExpress' ||
        project.patientPropertyConfig?.phoneNumber === 'required',
    },
    email: {
      name: 'email',
      label: 'Email',
      isVisible: project.patientPropertyConfig?.email !== 'hidden',
      isRequired: project.patientPropertyConfig?.email === 'required',
    },
    orderingPhysicianNpiId: {
      name: 'orderingPhysicianNpiId',
      label: 'Ordering Physician NPI ID',
      // Physician details should be visible only during Patient creation.
      isVisible: patient == null && project.patientPropertyConfig?.physicianDetails !== 'hidden',
      isRequired: patient == null && project.patientPropertyConfig?.physicianDetails === 'required',
    },
    orderingPhysicianFirstName: {
      name: 'orderingPhysicianFirstName',
      label: 'Ordering Physician First Name',
      // Physician details should be visible only during Patient creation.
      isVisible: patient == null && project.patientPropertyConfig?.physicianDetails !== 'hidden',
      isRequired: patient == null && project.patientPropertyConfig?.physicianDetails === 'required',
    },
    orderingPhysicianLastName: {
      name: 'orderingPhysicianLastName',
      label: 'Ordering Physician Last Name',
      // Physician details should be visible only during Patient creation.
      isVisible: patient == null && project.patientPropertyConfig?.physicianDetails !== 'hidden',
      isRequired: patient == null && project.patientPropertyConfig?.physicianDetails === 'required',
    },
    deviceBarcode: {
      name: 'deviceBarcode',
      label: 'Barcode',
      // Barcode should be visible/required only during Patient creation.
      isVisible: patient == null && project.labelIdentifierSource === 'barcode',
      isRequired: patient == null && project.labelIdentifierSource === 'barcode',
    },
  };

  useEffect(() => {
    if (visible && !patient) {
      form.setFieldValue('orderTemplateId', defaultOrderTemplateId);
    }
  }, [visible, defaultOrderTemplateId]);

  const handleFormSubmit = () => {
    const customAttributeValues: Record<string, any> = {};
    form
      .validateFields()
      .then((values: any) => {
        const allKeys = Object.keys(values);
        const customAttributeKeys = allKeys.filter((key) => key.startsWith('order:'));

        customAttributeKeys.forEach((key) => {
          const attributeName = key.replace('order:', '');
          customAttributeValues[attributeName] = values[key];
        });

        const filteredValues = allKeys
          .filter((key) => !customAttributeKeys.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: values[key] }), {}) as any;

        if (patientFormValues.deviceBarcode.isVisible) {
          const barcode = filteredValues.deviceBarcode?.trim();
          filteredValues.deviceBarcode = barcode || undefined;
        } else {
          filteredValues.deviceBarcode = undefined;
        }

        // smsConsent is stored as a timestamp by the API. If consent is given, generate a new timestamp now.
        // Otherwise the consent is not given or withdrawn, set it to null.
        filteredValues.smsConsent = filteredValues.smsConsent ? new Date().toISOString() : null;

        onOk({
          ...filteredValues,
          ...(filteredValues.dob ? { dob: filteredValues.dob.format('YYYY-MM-DD') } : {}),
          ...(customAttributeValues ? { customAttributeValues } : {}),
          projectId: project.id,
        });
      })
      .catch((info: any) => {
        console.log('Validate Failed:', info);
      });
  };

  const getNumberAttributesFirstColumn = (numAttribs: number) => Math.floor(numAttribs / 2) + (numAttribs % 2 ? 1 : 0);

  return (
    <Modal
      wrapClassName="Modal PatientModal"
      visible={visible}
      title={patient ? 'Edit Patient' : 'Create Patient'}
      okText={patient ? 'Save' : 'Create'}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleFormSubmit}
      confirmLoading={loading}
      centered
      width="720px"
      forceRender
    >
      <Form className="Form PatientModal__Form" form={form} layout="vertical" name="PatientModalForm">
        <p className="subtitle">Fields marked with an asterisk (*) are required.</p>
        {project.useOrderTemplates && !patient && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="orderTemplateId"
                label="Order Configuration"
                initialValue={selectedOrderTemplateId}
                rules={[
                  {
                    required: true,
                    message: 'Please select order config',
                  },
                ]}
              >
                <Select
                  placeholder="Please select an order configuration"
                  data-testid="patient-modal_orderConfig-select"
                  onSelect={setSelectedOrderTemplateId}
                >
                  {orderTemplates
                    .filter((template) => !template.isCustomerOrderable === false)
                    .map((orderTemplate) => (
                      <Select.Option
                        key={orderTemplate.id}
                        value={orderTemplate.id}
                        data-testid={`patient-modal_orderConfig-option-${orderTemplate}`}
                      >
                        {orderTemplate.name}
                      </Select.Option>
                    ))}
                  <Select.Option key={NO_ORDER_PLEASE} value={NO_ORDER_PLEASE}>
                    Do not place an order
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={12}>
            {patientFormValues.firstName.isVisible && (
              <Form.Item
                name={patientFormValues.firstName.name}
                label={patientFormValues.firstName.label}
                rules={[
                  {
                    required: patientFormValues.firstName.isRequired,
                    message: 'Please input the first name!',
                  },
                ]}
              >
                <Input autoComplete="given-name" />
              </Form.Item>
            )}
            <Form.Item
              name={patientFormValues.lastName.name}
              label={patientFormValues.lastName.label}
              rules={[{ required: patientFormValues.lastName.isRequired, message: 'Please input the last name!' }]}
            >
              <Input autoComplete="family-name" />
            </Form.Item>

            {patientFormValues.dob.isVisible && (
              <Form.Item
                name={patientFormValues.dob.name}
                label={patientFormValues.dob.label}
                rules={[
                  {
                    required: patientFormValues.dob.isRequired,
                    message: 'Please input date of birth!',
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" format="YYYY-MM-DD" />
              </Form.Item>
            )}

            {patientFormValues.assignedSex.isVisible && (
              <Form.Item
                name={patientFormValues.assignedSex.name}
                label={patientFormValues.assignedSex.label}
                rules={[
                  {
                    required: patientFormValues.assignedSex.isRequired,
                    message: 'Please input assigned sex at birth!',
                  },
                ]}
              >
                <Select data-testid="patient-modal_assignedSex-select">
                  {Object.values(ASSIGNED_SEXES).map((assignedSex) => (
                    <Select.Option
                      key={assignedSex}
                      value={assignedSex}
                      data-testid={`patient-modal_assignedSex-option-${assignedSex}`}
                    >
                      {assignedSex.charAt(0).toUpperCase() + assignedSex.slice(1)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {patientFormValues.gender.isVisible && (
              <Form.Item
                name={patientFormValues.gender.name}
                label={patientFormValues.gender.label}
                rules={[
                  {
                    required: patientFormValues.gender.isRequired,
                    message: 'Please input gender!',
                  },
                ]}
              >
                <Select>
                  {Object.keys(GENDERS).map((gender) => (
                    <Select.Option key={gender} value={gender}>
                      {gender.charAt(0).toUpperCase() + gender.slice(1)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {patientFormValues.race.isVisible && (
              <Form.Item
                name={patientFormValues.race.name}
                label={patientFormValues.race.label}
                rules={[
                  {
                    required: patientFormValues.race.isRequired,
                    message: 'Please input the race!',
                  },
                ]}
              >
                <Select>
                  {Object.values(RACES).map((race) => (
                    <Select.Option key={race} value={race}>
                      {race}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {patientFormValues.subjectId.isVisible && (
              <Form.Item
                name={patientFormValues.subjectId.name}
                label={patientFormValues.subjectId.label}
                rules={[
                  {
                    required: patientFormValues.subjectId.isRequired,
                    message: 'Please input the Subject Id!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {patientFormValues.orderingPhysicianNpiId.isVisible && (
              <Form.Item
                name={patientFormValues.orderingPhysicianNpiId.name}
                label={patientFormValues.orderingPhysicianNpiId.label}
                rules={[
                  {
                    required: patientFormValues.orderingPhysicianNpiId.isRequired,
                    message: 'Please input Ordering Physician NPI ID!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {patientFormValues.orderingPhysicianFirstName.isVisible && (
              <Form.Item
                name={patientFormValues.orderingPhysicianFirstName.name}
                label={patientFormValues.orderingPhysicianFirstName.label}
                rules={[
                  {
                    required: patientFormValues.orderingPhysicianFirstName.isRequired,
                    message: 'Please input Ordering Physician First Name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {patientFormValues.orderingPhysicianLastName.isVisible && (
              <Form.Item
                name={patientFormValues.orderingPhysicianLastName.name}
                label={patientFormValues.orderingPhysicianLastName.label}
                rules={[
                  {
                    required: patientFormValues.orderingPhysicianLastName.isRequired,
                    message: 'Please input Ordering Physician Last Name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              name={patientFormValues.address1.name}
              label={patientFormValues.address1.label}
              rules={[
                { required: patientFormValues.address1.isRequired, message: 'Please input shipping address #1!' },
              ]}
            >
              <Input autoComplete="address-line1" />
            </Form.Item>

            {patientFormValues.address2.isVisible && (
              <Form.Item
                name={patientFormValues.address2.name}
                label={patientFormValues.address2.label}
                rules={[
                  {
                    required: patientFormValues.address2.isRequired,
                    message: 'Please input the shipping address #2!',
                  },
                ]}
              >
                <Input autoComplete="address-line2" />
              </Form.Item>
            )}
            <Form.Item
              name={patientFormValues.city.name}
              label={patientFormValues.city.label}
              rules={[{ required: patientFormValues.city.isRequired, message: 'Please input shipping city!' }]}
            >
              <Input autoComplete="address-level2" />
            </Form.Item>

            <Form.Item
              name={patientFormValues.district1.name}
              label={patientFormValues.district1.label}
              rules={[{ required: patientFormValues.district1.isRequired, message: 'Please input shipping state!' }]}
            >
              <Input autoComplete="address-level1" />
            </Form.Item>

            <Form.Item
              name={patientFormValues.postalCode.name}
              label={patientFormValues.postalCode.label}
              rules={[{ required: patientFormValues.postalCode.isRequired, message: 'Please input postal code!' }]}
            >
              <Input autoComplete="postal-code" />
            </Form.Item>

            <Form.Item
              name={patientFormValues.country.name}
              label={patientFormValues.country.label}
              rules={[
                { required: patientFormValues.country.isRequired, message: 'Please input country!' },
                { len: 2, message: 'Please enter a 2 letter ISO 3166-1 alpha-2 country code!' },
              ]}
              initialValue="US"
            >
              <Input autoComplete="country" />
            </Form.Item>

            {patientFormValues.phoneNumber.isVisible && (
              <Form.Item
                name={patientFormValues.phoneNumber.name}
                label={patientFormValues.phoneNumber.label}
                rules={[
                  {
                    ...(patientFormValues.phoneNumber.isRequired ? { required: true } : { required: false }),
                    message: 'Please input phone number!',
                  },
                  {
                    validator: (_, phoneNumber = '') =>
                      phoneNumber.length === 0 || isValidPhoneNumber(phoneNumber)
                        ? Promise.resolve()
                        : Promise.reject(new Error()),
                    message: 'Please enter a valid US phone number!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {patientFormValues.deviceBarcode.isVisible && (
              <Form.Item
                name={patientFormValues.deviceBarcode.name}
                label={patientFormValues.deviceBarcode.label}
                rules={[{ required: patientFormValues.deviceBarcode.isRequired, message: 'Please input barcode!' }]}
              >
                <Input />
              </Form.Item>
            )}

            {patientFormValues.email.isVisible && (
              <Form.Item
                name={patientFormValues.email.name}
                label={patientFormValues.email.label}
                rules={[
                  { type: 'email', message: 'Invalid Email address!' },
                  {
                    required: patientFormValues.email.isRequired,
                    message: 'Please input the email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
        </Row>
        {showCustomAttributeFields && (
          <Row gutter={24}>
            <Col span={12}>
              <h3>Custom Order Attributes</h3>
              {generateCustomAttributeElements(
                selectedCustomAttributes.slice(0, getNumberAttributesFirstColumn(numCustomAttribs))
              )}
            </Col>
            {numCustomAttribs > 1 && (
              <Col span={12}>
                <h3>&nbsp;</h3>
                {generateCustomAttributeElements(
                  selectedCustomAttributes.slice(getNumberAttributesFirstColumn(numCustomAttribs))
                )}
              </Col>
            )}
          </Row>
        )}
        {patientFormValues.phoneNumber.isVisible && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.phoneNumber === undefined) {
                // undefined means the form is being initialized, don't run any custom logic just yet.
                return false;
              }

              const phoneNumberChanged = prevValues.phoneNumber !== currentValues.phoneNumber;

              // If the phone number changes, withdraw SMS consent automatically.
              // Whoever is changing the number should re-opt the patient back in manually for the new number.
              if (phoneNumberChanged) {
                form.setFieldsValue({ smsConsent: false });
              }

              return phoneNumberChanged;
            }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="smsConsent"
                valuePropName="checked"
                data-testid="patient-modal_sms-consent-form-item-root"
              >
                <Checkbox
                  disabled={!isValidPhoneNumber(getFieldValue('phoneNumber'))}
                  data-testid="patient-modal_sms-consent-checkbox"
                >
                  The participant has consented to receive SMS reminders at the frequency established for this project
                  and has confirmed that the phone number provided is for a mobile phone capable of receiving messages.
                  Message and data rates may apply. View terms/conditions and privacy policy{' '}
                  <a
                    href="https://www.tassoinc.com/privacy-policy"
                    data-testid="patient-modal_sms-consent-privacy-policy-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here <ExportOutlined />
                  </a>
                  .
                </Checkbox>
              </Form.Item>
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default PatientModal;
